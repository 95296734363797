const idPublicaciones =
    [
        {
            idPublicacion : '7181736275366821888',
        },
        {
            idPublicacion : '7166906426861334528',
        },
        {
            idPublicacion : '7175927026254548992',
        },
        {
            idPublicacion : '7169377975824592896',
        }
    ]

export const getIdPublicaciones = () => {
    return idPublicaciones;
}

//https://www.linkedin.com/feed/update/urn:li:activity:7052361856807579649
//https://www.linkedin.com/feed/update/urn:li:activity:7044711327583322112
//https://www.linkedin.com/feed/update/urn:li:activity:7046874463539183618
//https://www.linkedin.com/feed/update/urn:li:activity:7041412640379650048